<template>
    <div class="px-3 py-2">
        <b-sidebar aria-labelledby="sidebar-no-header-title" width="80%" no-header id="add_voucher" backdrop style="direction:ltr" right :title="pagename" shadow >
            <template #default="{ hide }">
                <div class="m-1 text-start" :style="`direction:`+lang.dir">
                    <div style="width:150px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                        <span style="font-size:.8em;">{{pagename}}</span>
                    </div>
                    <div @click="hide" id="vohclose" style="width:100px;text-align:center;float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
                        <span>{{lang.close}}</span>
                    </div>
                </div>
                <v-divider></v-divider>
                <div class="px-3 py-2" :style="`direction:`+lang.dir">
                    <v-form ref="apform">

                        <v-row>
                            <v-col cols="12" md="2" sm="4">
                                <b-form-input v-model="classid" @change="getName()"></b-form-input>
                            </v-col>
                            <v-col cols="12" md="10" sm="8">
                                <b-form-input v-model="acount_name" @change="getName()">{{acount_name}}</b-form-input>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-simple-table>
                                <thead>
                                    <tr>
                                        <th>رقم الحساب</th>
                                        <th>اسم الحساب</th>
                                        <th style="width:75px;">الاجراء</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item,index) in resclass" :key="index">
                                        <td>{{item.classid}}</td>
                                        <td>{{item.namear}}</td>
                                        <td>{{item.nameen}}</td>
                                        <td> 
                                            <b-button class="btn btn-sm btn-success">استخدم</b-button>
                                        </td>
                                    </tr>
                                </tbody>
                            </v-simple-table>
                        </v-row>
                    </v-form>
                </div>
            </template>
            
        </b-sidebar>
        <vue-snotify></vue-snotify>
    </div>
</template>

<script>
import axios from 'axios'
export default{
    data() {
        return {
            pagename: "بحث",
            classid: '',
            acount_name: '',
            resclass: [],
            allclasses: [],
        }
    },
    computed:{
        lang: {
            get: function(){
                if(this.$cookies.get(this.$store.state.LangCooki) != null){
                    if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                        return this.$store.state.lang.en;
                    }else{
                        return this.$store.state.lang.ar;
                    }
                }
                else if(this.$store.state.deflang == 'en'){
                    return this.$store.state.lang.en;
                }else{
                    return this.$store.state.lang.ar;
                }
            },
            set: function(newValue){
                return newValue
            }
        },
    },
    created() {
        this.getClasses()
    },
    methods: {
        
        getClasses(){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type","getChartOfAccount")
            post.append("auth",this.$cookies.get(this.$COOKIEPhase))
            post.append("data[lang]","ar");
            post.append('data[calssid]',0);

            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                // // console.log(res);
                this.allclasses = res.results.data;
            })
        },
    }
}
</script>